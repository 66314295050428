import React from 'react'
import Layout from "../components/Layout"

const error = () => {
    return (
        <Layout>
            sorry that page doesnt exist.
        </Layout>
    )
}

export default error
